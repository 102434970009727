const pointerEvents = {
  onPointerEnter: () => {
    document.body.style.cursor = "pointer";
  },
  onPointerLeave: () => {
    document.body.style.cursor = "default";
  },
};

export default pointerEvents;
